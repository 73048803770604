<template>
    <v-card class="transparent" flat tile>
  <v-card-text>
      <v-text-field v-model="faqsearch" label="Search" append-icon="mdi-file-search"/>
   <v-expansion-panels class="mb-6">
        <v-expansion-panel
            v-for="question in ComputedFrequentlyAskedQuestions"
            :key="question.itemObjKey">
                <v-expansion-panel-header expand-icon="mdi-menu-down">
                    <v-card-subtitle style="font-size:  1.15em;">
                <v-icon color="primary">mdi-plus</v-icon> {{question.Question}}
                    </v-card-subtitle>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                   
                    <v-card-text v-html="question.Answer">
                    </v-card-text>
                    <v-card-actions v-if="question.HasActionButton" :class="question.ButtonAlignment">
                    <v-btn v-if="question.ActionButtonType === 'URL' && question.Fancy" :dark="question.ActionBtnDark" x-large width="200"  :outlined="question.Outlined" :href="question.ActionButtonRoute" target="_blank" :style="question.Outlined? 'font-size:0.7em;' : 'font-size:0.7em;background-color:'+question.ButonColor.hexa">
                        {{question.ActionButtonName}}
                    </v-btn>
                    <v-btn v-if="question.ActionButtonType === 'Route' && question.Fancy" :dark="question.ActionBtnDark" x-large width="200" :outlined="question.Outlined" :to="'/'+question.ActionButtonRoute" :style="question.Outlined? 'font-size:0.7em;' : 'font-size:0.7em;background-color:'+question.ButonColor.hexa">
                        {{question.ActionButtonName}}
                    </v-btn>
                    <v-btn v-if="question.ActionButtonType === 'URL' && !question.Fancy" :dark="question.ActionBtnDark" :href="question.ActionButtonRoute" :outlined="!question.Outlined" target="_blank" :style="question.Outlined? '' : 'background-color:'+question.ButonColor.hexa">
                            {{question.ActionButtonName}}
                        </v-btn>
                        <v-btn v-if="question.ActionButtonType === 'Route' && !question.Fancy" :dark="question.ActionBtnDark" :to="'/'+question.ActionButtonRoute" :outlined="!question.Outlined"  :style="question.Outlined? '' : 'background-color:'+question.ButonColor.hexa">
                            {{question.ActionButtonName}}
                        </v-btn>
                </v-card-actions>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
  </v-card-text>
    </v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import ContentEditableField from '@/components/Database/ContentEditableField'
export default {
    props: ['FrequentlyAskedQuestions'],
    components: {
        ContentEditableField
    },
    data (){
        return{
            faqsearch: '',
            items: [],
            FAQDialog: false,
            elmnt: {									
                RowColor: '#00ffff',									
                Name: 'FAQ',									
                ShowComponents: true,									
                Icon: 'mdi-help',									
                Title: 'About',	
                SelectedFAQ: [],								
                Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },									
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:6,									
                FlexLarge: 8,									
                FlexMedium: 10,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,
                HasActionButton: false,
                Fancy: true,
                Outlined: true,
                ButtonColor: {
                    hexa: '#ff0000'
                },
                ButtonAlignment: 'justify-end',
                ActionButtonName: 'Read More',
                ActionButtonType: 'Route',
                ActionButtonRoute: ''								
            },
            editedQuestionIndex: -1,
            editedQuestion: {
                Question: '',
                Answer: '',
                HasActionButton: false,
                PublishType: 'Public',
                Fancy: true,
                Outlined: true,
                ButtonColor: {
                    hexa: '#ff0000'
                },
                ButtonAlignment: 'justify-end',
                ActionButtonName: 'Read More',
                ActionButtonType: 'Route',
                ActionButtonRoute: ''
            },
            defaultQuestion:{
                Question: '',
                Answer: '',
                HasActionButton: false,
                 PublishType: 'Public',
                Fancy: true,
                Outlined: true,
                ButtonColor: {
                    hexa: '#ff0000'
                },
                ButtonAlignment: 'justify-end',
                ActionButtonName: 'Read More',
                ActionButtonType: 'Route',
                ActionButtonRoute: ''
            }
        }
    },
    computed:{
        ComputedFrequentlyAskedQuestions(){
            return this.FrequentlyAskedQuestions.filter(quest => {
                if(this.faqsearch){
                    return quest.Question.toLowerCase().includes(this.faqsearch)
                    || quest.LowerKeyWords.includes(this.faqsearch)
                    || quest.Answer.includes(this.faqsearch)
                }
                else{
                    return quest
                }
            })
        },
        ComputedElement(){
            let elmnt = this.elmnt
            elmnt.SelectedFAQ = this.FrequentlyAskedQuestions
            return elmnt
        }
    },
    created(){
        
    },
    methods:{
        UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
            FieldObject[prop] = value
            console.log(AdditionalSaveMethod)
            if(AdditionalSaveMethod){
                this[AdditionalSaveMethod]()
            }
            console.log(FieldObject,this.editedQuestion)
            },
        SaveFAQ(){
            if(this.editedQuestionIndex === -1){
                this.editedQuestion.LowerKeyWords = this.editedQuestion.KeyWords.map(keyword => {return keyword.toLowerCase()}),
                db.collection('frequentlyaskedquestions').add(this.editedQuestion).then(doc => {
                    console.log('created new FAQ under '+doc.id)
                })
            }
            else{
                this.editedQuestion.LowerKeyWords = this.editedQuestion.KeyWords.map(keyword => {return keyword.toLowerCase()}),
                db.collection('frequentlyaskedquestions').doc(this.editedQuestion.id).set(this.editedQuestion).then(doc => {
                    console.log('set and updated FAQ')
                })
            }
        },
        ActivateFAQDialog(question,questionindex){
            if(question){
                this.editedQuestion = Object.assign({},question)
                this.editedQuestionIndex = questionindex
                this.FAQDialog = true
            }
            else{
                this.FAQDialog = true
            }
        },
        DeActivateFAQDialog(question){
           this.FAQDialog = false
           this.editedQuestion = Object.assign({},this.defaultQuestion)
           this.editedQuestionIndex = -1
        }
    }
}
</script>

<style>

</style>